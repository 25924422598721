import { Router } from '@angular/router';
import { CodeName } from '@client-portal/models/common';

export function codeNameToDisplayValue(
  codeName: CodeName<string | null>,
  joinChar: string = '-',
): string {
  if (!codeName) return '';
  return displayTwoValuesWithChar(codeName.code, codeName.name, joinChar);
}
export function displayTwoValuesWithChar(
  leftParam: string,
  rightParam: string | null,
  joinChar: string = '-',
): string {
  if (leftParam && rightParam) return `${leftParam} ${joinChar} ${rightParam}`;
  if (!leftParam && rightParam) return rightParam;
  return leftParam;
}

export function goBack(router: Router): void {
  if (window.history.length > 2) {
    window.history.go(-2);
  } else {
    void router.navigateByUrl('/');
  }
}
